import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IntegrationService } from '../../../services/integration/integration.service';
import { AssignmentDto } from '../../../dtos/AssignmentDto';
import { AssignmentActionType } from '../../../dtos/AssignmentActionType';
import { ViewSource } from '../../../dtos/ViewSource';
import { ShowDetailsWrapper } from '../../../dtos/ShowDetailsWrapper';
import {DataService} from "../../../services/data.service";
import "@neo-ds/components/button";
import "@neo-ds/components/tooltip";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-bmw-assignment-card[assignment]',
  templateUrl: './bmw-assignment-card.component.html',
  styleUrls: ['./bmw-assignment-card.component.scss']
})
export class BmwAssignmentCardComponent implements OnInit {
  constructor(private service: IntegrationService,
              private dataService: DataService,
              private translate: TranslateService) {}

  @Input()
  assignment: AssignmentDto | undefined;
  action: AssignmentActionType | undefined;

  @Output()
  showPackageView = new EventEmitter<ShowDetailsWrapper>();

  modalIsOpen = false;

  // calling this will open the modal
  openMethod(action: string) {
    if ('WITHDRAW' === action) this.action = AssignmentActionType.WITHDRAW;
    else if ('REJECT' === action) this.action = AssignmentActionType.REJECT;
    else if ('APPROVE' === action) this.action = AssignmentActionType.APPROVE;
    this.modalIsOpen = true;
  }

  // calling this will dismiss the modal
  closeModal() {
    this.modalIsOpen = false;
  }

  changeAssignment() {
    if (this.assignment && this.assignment.gcid) {
      const gcid = this.assignment.gcid;
      this.service
        .changeAssignmentStatus(gcid, this.action)
        .subscribe((_ignore) => {
          this.action = undefined;
          this.service.getAssignment(gcid).subscribe((val) => {
            if (val) {
              this.assignment = val;
              this.service.redirectBackTo(
                this.assignment.id,
                this.assignment.status,
                ViewSource.ASSIGNMENT
              );
            }
          });
          this.closeModal();
        });
    }
  }

  get showAcceptOrRejectButton() {
    return ['REQUESTED'].includes(this.assignment?.status || '');
  }

  get showRevoke() {
    return ['ACCEPTED', 'ACTIVE'].includes(this.assignment?.status || '');
  }

  get disableButtons(){
    if(this.assignment){
      return this.assignment.gcid !== this.dataService.gcId;
    }
    return false;
  }

  showPackageDetailView() {
    const wrapper: ShowDetailsWrapper = {
      source: ViewSource.ASSIGNMENT,
      shouldShow: true
    };
    this.showPackageView.emit(wrapper);
  }

  ngOnInit(): void {
    console.log()
    document.getElementById('tooltipAssignment')?.setAttribute('heading', this.translate.instant('assignment.package-details.request.' +
      this.assignment?.serviceType + '.name') || '');
  }
}
